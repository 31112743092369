import { Component, ViewChild, OnInit } from '@angular/core';
import { CustomEventService } from '../../services/custom-event.service';
import {FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
// import { LoginpopupService } from '../../services/loginpopup.service';

import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { RestService } from "../../services/rest.service";
// import { HideshowheaderfooterService } from "../../services/hideshowheaderfooter.service";
import { AuthService } from "../../services/auth.service";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { Md5 } from "ts-md5";
import { EncrdecrService } from "../../services/encryption.service";
// import { SocialAuthService, SocialUser } from "@abacritt/angularx-social-login";
// import { FacebookLoginProvider, GoogleLoginProvider, GoogleSigninButtonModule } from "@abacritt/angularx-social-login";
import { CustomstorageService } from '../../services/customstorage.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @ViewChild("login") loginmodal: any;
  @ViewChild("common_modal") common_modal: any;
  @ViewChild("content") regmodal: any;
  @ViewChild("emailVerify") emailVerify: any;
  @ViewChild("forgotpassword") forgotpassword: any;
  @ViewChild("otpform") otpTempform: any;
  @ViewChild("onetimeVerify") onetimeVerify: any;
  @ViewChild("onetimelogin") onetimelogin: any;

  public bannerUrl = "";

  public guestoption = false;
  closeResult: any;
  // public countrylist: any[];
  public continuelogin = false;
  public common_popup_title = "";
  public common_popup_message = "";
  loginerror = false;
  loginerrortext = "";
  loader = false;
  guestlogin = false;
  otpsendText = "";
  regerror = false;
  regerrortext = "";
  otperror = false;
  otperrortext = "";forgotfield="";
  forgotpasswordText="";
  forgotpassworderror="";
  public cartitems = [];

  public guestloginobject = {
    responsecode: "200",
    status: "Continue as Guest",
    user_id: "0",
    first_name: null,
    middle_name: null,
    last_name: null,
    email: "indtest1@mailinator.com",
    mobile: null,
    image: "",
    street: null,
    town: null,
    postcode: null,
    city_id: "0",
    city_name: null,
    country_id: "1",
    sessiontoken: "",
    address_book: null,
    address_book_list:[],
    subscribed_bs_list:[],
    bookyup_subscription: false,
    sms_subscription: false
  };
  public isLoggedIn = false;
  public loguser = {
    email: <any>"",
    password: <any>"",
    remote_ip: "test",
    user_agent: "test"
  };

  public reg = {
    first_name: <any>"",
    middle_name: <any>"",
    last_name: <any>"",
    email: <any>"",
    password: <any>"",
    cpassword: <any>"",
    street: <any>"",
    town: <any>"",
    postcode: <any>"",
    country: <any>"",
    city: <any>"",
    mobile_no: <any>"",
    phone_no: <any>"",
    birth_month: <any>"",
    address:<any>""
  };

  public verifyf = {
    email: <any>"",
    otp: <any>""
  };

  public oneloginpayload = {
    otp_input: <any>"",
    otp: <any>""
  };
  public registerForm!: FormGroup;
  public loginFormGroup : FormGroup;
  constructor(
    public customEventservice: CustomEventService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    // public loginpopS: LoginpopupService,
    public restProvider: RestService,
    public authServ: AuthService,
    public router: Router,
    public actvRts: ActivatedRoute,
    public customstorageService: CustomstorageService,
    public encodedecode: EncrdecrService,
    public storage: CustomstorageService,
    public localst:LocalStorageService
    // private authService: SocialAuthService,
    // public hideShow: HideshowheaderfooterService,
  ){
    this.registerForm= this.formBuilder.group({
      firstName: new FormControl(null, [Validators.required]),
      phoneNumber: new FormControl(null, [Validators.required,Validators.pattern('^0[0-9]{10}$')]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required,Validators.minLength(6)]),
      cpassword: new FormControl(null, [Validators.required])
    },
    {
      validators: this.passwordMatch('password','cpassword')
    });
    this.customEventservice.getBanner().subscribe(url=>{
      this.bannerUrl = url.bannerurl;
      // console.log(url.bannerurl)
    });

    this.loginFormGroup =  this.formBuilder.group({
      email : new FormControl(null, [Validators.required, Validators.email]),
      password : new FormControl(null, [Validators.required])
    })
    // this.hideShow.updateHideShowStatus(true);
    // this.hideShow.init();
    // this.countrylist = this.customstorageService.retrieve("countryList");
    // //this.country = this.customstorageService.retrieve('countryList');

    this.customEventservice.popupcall().subscribe(popdata => {
      // console.log(popdata)
      this.continuelogin = popdata.text;
      this.loginpop(this.loginmodal);
    });

    this.customEventservice.listencommonpopup().subscribe(comonpopdata=>{
      console.log('hello');
      console.log(comonpopdata);
     
      this.common_popup_title=comonpopdata.popuptitle;
      this.common_popup_message=comonpopdata.poptext;
      this.modalService.open(this.common_modal);

    });
  }
  ngOnInit() {
    let is_loggedin = this.authServ.isLoggednIn();
    console.log(is_loggedin)
    if (is_loggedin) {
      this.isLoggedIn = true;
      this.checkuserid();
    }
    
    this.customstorageService.observe("usertoken").subscribe(nval => {
      // console.log("nval ==", nval);
      if (nval == null) {
        this.isLoggedIn = false;
        this.guestlogin=false;
      } else {
        this.isLoggedIn = true;
        this.checkuserid();
      }
    });

    /* this.authService.authState.subscribe((user: any) => {
      this.socuser = user;
      //console.log(user);
      //console.log(this.socuser);
      if(user != null){
          
        this.socialregister();
      }
    }); */

    $(function () {
      //$(".wrapmenu").adaptiveMenu();
      $(".navbar-toggler").click(function (e: any) {
        e.stopPropagation();
        $(".navbar-collapse").toggleClass("on");
      });
      $("body").click(function () {
        if ($(".navbar-collapse").is(":visible")) { $(".navbar-collapse").removeClass("on"); }
      });
    });
  }
  
  async createGuestlogin() {
    this.cartitems = await this.customstorageService.getLocalstorage("cartlist");
    // console.log(this.cartitems);return;
    this.restProvider
        .postdataapi(this.cartitems, "users/guest_login")
        .subscribe((result: any) => {
          // console.log(result);return;
          this.loader = false;
          if (result.responsecode == 200) {
            this.guestloginobject.sessiontoken = result.guest_session_token
            this.storage.setLocalstorage("userdata", this.guestloginobject);
            this.storage.setSessionstorage("userdata", this.guestloginobject);
            this.authServ.setSecureToken( this.guestloginobject.sessiontoken);

            this.customstorageService.setLocalstorage("usertoken", this.authServ.getSecureToken());
            this.customEventservice.logincomplete();
            this.modalService.dismissAll();
            this.reloadaffterlogin();

          } else {
            this.loginerror = true;
            this.loginerrortext = result.status;
          }
        }, (error: any) => {
          // console.log(error);
          // console.log("data fetching failed");
          // this.showdloader = false;
        })
    /* const md5 = new Md5();
    const rand = String(Math.random() + "bookyup");
    ////console.log(md5.appendStr(rand).end());
    this.guestloginobject.sessiontoken = String(md5.appendStr(rand).end());
    await this.storage.setLocalstorage("userdata", this.guestloginobject);
    await this.storage.setSessionstorage("userdata", this.guestloginobject);
    this.authServ.setSecureToken(this.guestloginobject.sessiontoken);
    await this.storage.setLocalstorage("usertoken", this.authServ.getSecureToken());
    this.modalService.dismissAll();
    this.customEventservice.logincomplete();
    this.reloadaffterlogin(); */
  }
  async checkuserid() {
    let udata = await this.customstorageService.getLocalstorage("userdata")
    if (udata != null && udata != undefined) {
      if (udata.user_id == 0) {
        this.guestlogin = true;
      } else {
        this.guestlogin = false;
      }
    }
  }
  loginpop(content: any) {
    this.guestoption =  this.customEventservice.getguesoption();
    console.log(this.guestoption)
    let modalsize = this.guestoption ? "lg" : "md";
    this.modalService
      .open(content, { size: modalsize, ariaLabelledBy: "modal-basic-title" })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  get loginFormValidate(){
    return this.loginFormGroup.controls;
  }
  loginForm() {
    // console.log(f1);
    this.loginerror = false;
    if (this.loginFormGroup.invalid) {
      this.loginFormGroup.controls['email'].markAsTouched();
      this.loginFormGroup.controls['password'].markAsTouched();
      return;
    }else {
      this.loader = true;
      this.loguser.email = this.loginFormGroup.controls['email'].value;
      this.loguser.password = this.encodedecode.newset(this.loginFormGroup.controls['password'].value);
      this.restProvider
        .postdataapi(this.loguser, "users/login")
        .subscribe((result: any) => {
          // console.log(result);
          this.loader = false;
          if (result.responsecode == 200) {
            this.customstorageService.setLocalstorage("userdata", result);
            this.customstorageService.setSessionstorage("userdata", result);

            this.authServ.setSecureToken(result.sessiontoken);

            this.customstorageService.setLocalstorage("usertoken", this.authServ.getSecureToken());
            this.customEventservice.logincomplete();
            this.modalService.dismissAll();
            this.customstorageService.removeLocalstorage("addressDetails");

            this.reloadaffterlogin();

          } else {
            this.loginerror = true;
            this.loginerrortext = result.status;
          }
        }, (error: any) => {
          // console.log(error);
          // console.log("data fetching failed");
          // this.showdloader = false;
        })
    }
  }


  passwordMatch(password: string, cpassword: string){
    return(formGroup:FormGroup)=>{
      const pass = formGroup.controls[password];
      const cpass = formGroup.controls[cpassword];
      if(cpass.errors && !cpass.errors['passwordMatch']){
        return ;
      }
      if(pass.value != cpass.value){
        cpass.setErrors({passwordMatch:true});
      }else{
        cpass.setErrors(null);
      }
    }
  }
  get regValidator (){ return this.registerForm.controls}
  registerFormSubmit() {
    if (this.registerForm.invalid) {
      this.registerForm.controls['firstName'].markAsTouched();
      this.registerForm.controls['phoneNumber'].markAsTouched();
      this.registerForm.controls['email'].markAsTouched();
      this.registerForm.controls['password'].markAsTouched();
      this.registerForm.controls['cpassword'].markAsTouched();
      return;
    }else {
      this.otpsendText = "";
      ////console.log(this.reg);
      this.loader = true;
      this.reg.first_name = this.registerForm.controls['firstName'].value;
      this.reg.mobile_no = this.registerForm.controls['phoneNumber'].value;
      this.reg.email = this.registerForm.controls['email'].value;
      this.reg.password=this.encodedecode.newset(this.registerForm.controls['password'].value);
      this.reg.cpassword=this.encodedecode.newset(this.registerForm.controls['cpassword'].value);
      this.restProvider
        .postdataapi(this.reg, "users/register")
        .subscribe((result: any) => {
          //console.log(result);
          this.loader = false;
          this.regerror = false;
          this.regerrortext = "";
          if (result.responsecode == 200) {
            this.modalService.dismissAll();
            this.verifyf.email = this.reg.email;
            this.modalService.open(this.emailVerify, {backdrop: 'static'});
            this.otpsendText = result.status;
          } else {
            this.regerror = true;
            this.regerrortext = result.status;
          }
        });
    }
  }

  callregistarmodal() {

    this.open(this.regmodal);
  }

  open(content: any) {
    this.modalService
      .open(content, { size: "md", ariaLabelledBy: "modal-basic-title" })
      .result.then(
        result => {
          this.closeResult = `Closed with: ${result}`;
         // //console.log(this.closeResult);
        },
        reason => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
         // //console.log(this.closeResult);
        }
      );
  }

  resendOtp() {
    this.otperror = false;
    this.otperrortext = "";
    let emailverify = { email: this.verifyf.email };
    this.restProvider
      .postdataapi(emailverify, "users/resend_email_verify")
      .subscribe((result: any) => {
        ////console.log(result);
        this.verifyf.otp = "";

        this.otpsendText = result.status;
      });
  }
  
  otpverify() {
    this.otperror = false;
    this.otperrortext = "";
    this.otpsendText = "";

    if (this.verifyf.otp) {
      this.loader = true;
      this.restProvider
        .postdataapi(this.verifyf, "users/email_verify")
        .subscribe((result: any) => {
          this.loader = false;

          if (result.responsecode == 200) {
            this.customstorageService.setLocalstorage("userdata", result);
            this.storage.setSessionstorage("userdata", result);
            this.authServ.setSecureToken(result.sessiontoken);
            this.customstorageService.setLocalstorage("usertoken", this.authServ.getSecureToken());
            this.customEventservice.logincomplete();
            this.modalService.dismissAll();
            this.reloadaffterlogin();
          } else {
            this.otperror = true;
            this.otperrortext = result.status;
            setTimeout(()=>{
              this.otperrortext = '';
            },10000);
          }
        });
    } else {
      //this.otperror=true;
      //this.otperrortext="Verification code is required";
    }
  }

  reloadaffterlogin() {

    let refresh = this.customstorageService.getSessionstorage("loginrefresh");
    // console.log(refresh);
    if (refresh != null && refresh != undefined) {
      this.customstorageService.clearSessionstorage("loginrefresh");
      // window.location.reload();
    }
  }

  logoutuser() {
    this.customstorageService.removeLocalstorage("userdata");
    this.customstorageService.removeSessionstorage("userdata");
    this.customstorageService.removeLocalstorage("sociallogin");
    this.authServ.logout();
    window.location.href = "";
    // this.signOut();
  }

  signOut(): void {
    // this.authServ.signOut();
  }

  forgotpasswordmodal() {
    this.modalService
      .open(this.forgotpassword, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        result => {
          //this.closeResult = `Closed with: ${result}`;
          this.forgotpasswordText = "";
          this.forgotpassworderror = "";
          this.forgotfield="";
          
        },
        reason => {
          //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
   
  }

  forgotpasswordcall(f1:any,form: NgForm){
    if (f1) {
      this.loader = true;
      this.restProvider
        .postdataapi({"username":this.forgotfield}, "users/forgot_password")
        .subscribe((result: any) => {
          //console.log(result);
          this.loader = false;
          if (result.responsecode == 200) {
            this.forgotpasswordText = result.status;
            this.forgotpassworderror = "";
            form.resetForm();
          } else {
            this.loginerror = true;
            this.forgotpassworderror = result.status;
            this.forgotpasswordText = "";
            form.resetForm();
          }
        });
    }
  }

  oneverifycall(f1: any) {
    this.loginerror = false;
    if (f1) {
      this.loader = true;
      this.restProvider
        .postdataapi(this.oneloginpayload, "users/guest_otp_verify")
        .subscribe((result: any) => {
          //console.log(result);
          this.loader = false;
          if (result.responsecode == 200) {
            this.customstorageService.setLocalstorage("userdata", result);
            this.storage.setSessionstorage("userdata", result);

            this.authServ.setSecureToken(result.sessiontoken);

            this.customstorageService.setLocalstorage("usertoken", this.authServ.getSecureToken());
            this.modalService.dismissAll();
            this.customEventservice.logincomplete();
            this.reloadaffterlogin();
          } else {
            this.loginerror = true;
            this.loginerrortext = result.status;
          }
        })
    }
  }

  onelogincall(f1: any) {
    this.loader = true;
    ////console.log(this.loguser);
    this.restProvider
      .postdataapi(this.oneloginpayload, "users/guest_otp")
      .subscribe((result: any) => {
        //console.log(result);
        this.loader = false;
        if (result.responsecode == 200) {
          this.modalService.dismissAll();
          this.modalService.open(this.onetimeVerify);
        } else {
          this.loginerror = true;
          this.loginerrortext = result.status;
        }
      });
  }

  reonelogincall() {
    this.loader = true;
    this.restProvider
      .postdataapi(this.oneloginpayload, "users/guest_otp")
      .subscribe((result: any) => {
        //console.log(result);
        this.loader = false;
        if (result.responsecode == 200) {
          //this.loginerrortext = result.status;
          // this.loginerrortext = "Verification code resen sucessfully";
        } else {
          // this.loginerror = true;
          // this.loginerrortext = result.status;
        }
      });

  }
}
