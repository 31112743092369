import { Injectable, ɵConsole } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, ReplaySubject, from, of, range } from "rxjs";
import { map, filter, retry } from "rxjs/operators";
// import { LoginpopupService } from "./loginpopup.service";
import { AuthService } from "./auth.service";
// import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { CustomstorageService } from "./customstorage.service";
import { CustomEventService } from "./custom-event.service";
import { environment } from '../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  apiUrl = environment.apiUrl;
  deviceInfo: any;
  private isLogout = new Subject<any>();



  constructor(
    public http: HttpClient,
    // public loginpopS: LoginpopupService,
    // public localSt: LocalStorageService,
    // public sesnSt: SessionStorageService,
    public customStorage: CustomstorageService,
    public authServ: AuthService,
    private deviceService: DeviceDetectorService,
    public customEventService : CustomEventService
  ) { }

  apiCallLogout() {
    let param = "on";
    this.isLogout.next({ text: 'x' });
    this.customStorage.removeLocalstorage('userdata');
    this.customStorage.removeSessionstorage('userdata');
    // this.localSt.clear("userdata");
    // this.sesnSt.clear("userdata");
    this.authServ.logout();
    this.customEventService.setloginpop(param);
    // let log = this.loginpopS.openpopup();
    // this.sesnSt.store("loginrefresh", 'reqired');
    this.customStorage.setSessionstorage("loginrefresh", 'reqired');
  }
  logoutSubscribe() {
    return this.isLogout.asObservable();
  }

  postdataapi(data: any, fnc: any): Observable<any> {
    let headers = new HttpHeaders();
    // device data add
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceInfo.isMobile = this.deviceService.isMobile();
    this.deviceInfo.isTablet = this.deviceService.isTablet();
    this.deviceInfo.isDesktopDevice = this.deviceService.isDesktop();
    let encdevicinfo = btoa(JSON.stringify(this.deviceInfo));
    data.secure = encdevicinfo;
    // device end
    return this.http.post(this.apiUrl + fnc, JSON.stringify(data), { headers: headers });
  }
  getData(data: any, fnc: any): Observable<any> {
    // We do not subscribe here! We let the resolver take care of that...
    return this.http.post(this.apiUrl + fnc, JSON.stringify(data));
  }
}
